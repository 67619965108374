/* --------------------------------------------------
    Breadcrumb
-------------------------------------------------- */
.case .breadcrumb,
.case-detail .breadcrumb {
    padding: 0;
    border-bottom: 1px solid #787878;
    border-radius: 0;
    background-color: white;
}
.case .breadcrumb-item a,
.case-detail .breadcrumb-item a {
    text-decoration: none;
    color: #787878;
    font-weight: 700;
}
.case .breadcrumb-item a:hover,
.case-detail .breadcrumb-item a:hover {
    color: #0c3e72;
}
/* --------------------------------------------------
    Case
-------------------------------------------------- */
.case {
    margin: 60px 0;
}
/* --------------------------------------------------
    Case - Title
-------------------------------------------------- */
.case-title {
    margin-bottom: 1rem;
    color: #0c3e72;
}
@media (max-width: 992px) {
    .case-title {
        text-align: center;
    }
}
/* --------------------------------------------------
    Case - Total Count
-------------------------------------------------- */
.total-count {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75rem;
}
/* --------------------------------------------------
    Case - Category
-------------------------------------------------- */
.case .category,
.case .sub-category {
    list-style: none;
    font-weight: 700;
}
.case .category a {
    color: #0c3e72;
    font-size: 1.125rem;
}
.case .sub-category {
    margin: 0.375rem 0;
}
.case .sub-category a {
    color: #787878;
    font-size: 1rem;
}
.case .category a:hover,
.case .sub-category a:hover {
    color: #fe5c24;
    text-decoration: none;
}
/* --------------------------------------------------
    Case - Card Title
-------------------------------------------------- */
.case .card .card-title {
    color: #0c3e72;
    cursor: pointer;
}
.case .card .card-title:hover {
    color: #fe5c24;
}
/* --------------------------------------------------
    Case - Card Img
-------------------------------------------------- */
@media (min-width: 576px) {
    .case .carousel .slide img {
        height: 350px;
    }
}
@media (min-width: 768px) {
    .case .carousel .slide img {
        height: 250px;
    }
}
@media (min-width: 992px) {
    .case .carousel .slide img {
        height: 180px;
    }
}
/* --------------------------------------------------
    Case Detail
-------------------------------------------------- */
@media (min-width: 929px) {
    .case-detail .carousel .slide img {
        height: 500px;
    }
}
.case-detail .carousel .slide .legend2 {
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    color: #fff;
    padding: 10px;
    font-size: 15px;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.9);
}
/* --------------------------------------------------
    Case Detail - Table
-------------------------------------------------- */
.case-detail .case-table tr {
    border-bottom: 1px solid #dee2e6;
}
.case-detail .case-table tr td {
    color: #787878;
}

.case-detail .case-table .td-head {
    width: 7rem;
    font-weight: 700;
    color: #0c3e72;
}