.jumbotrons {
    position: relative;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    margin-bottom: 60px;
}
.jumbotrons:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    content: "";
    opacity: 0.6;
    z-index: -1;
}
.jumbotrons .row {
    align-items: center;
    justify-content: center;
    height: 100%;
}
.jumbotrons .jumbotron { 
    background-color: transparent !important;
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.9);
    text-align: center;
}
.jumbotrons .jumbotron h1 {
    font-size: calc(3rem + 4vw);
}
.jumbotrons .jumbotron p {
    font-size: 1.125rem;
}