/* --------------------------------------------------
    Header
-------------------------------------------------- */
.header {
    background-color:white;
    padding: 10px 0;
    z-index: 999;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
}
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
}
/* --------------------------------------------------
    Logo
-------------------------------------------------- */
.header .logo {
    height: 70px;
    width: auto;
}
.header .company {
    font-size: 1.125rem;
    font-weight: 600;
    color: #6c7492;
}
.header .company p{
    margin: 0.125rem 0;
}
/* --------------------------------------------------
    Desktop Menu 
-------------------------------------------------- */
.nav-menu ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}
.nav-menu ul li{
    position: relative;
    white-space: nowrap;
    padding: 0 0.5rem;
}
.nav-menu ul li a {
    display: block;
    padding: 5px 9px;
    position: relative;
    color: #0c3e72;
    transition: 0.3s;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}
.nav-menu ul > li:hover .menu-link,
.nav-menu ul > li > .menu-link.menu-active {
	color: #fe5c24;
}
/* --------------------------------------------------
    Desktop Sub Menu
-------------------------------------------------- */
.nav-menu .dropdown-content-hover .dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    z-index: 1;
    min-width: 170;
    background-color:white;
    border-radius: 5%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.nav-menu .dropdown-sub-content-hover .dropdown-sub-content {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #f9f9f9;
    border-radius: 5%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    transform: translate3d(137px, 0px, 0px);
}
.nav-menu .dropdown-content-hover:hover .dropdown-content,
.nav-menu .dropdown-sub-content-hover:hover .dropdown-sub-content {
    display: block;
}
.nav-menu .nav-sub-menu li:hover .dropdown-link,
.nav-menu .nav-sub-menu li > ul > li:hover .sub-dropdown-link {
	color: #fe5c24;
}
/* --------------------------------------------------
    Desktop Menu Toggle
-------------------------------------------------- */
.nav-toggle {
    border: 0;
    background: none;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
}
.nav-toggle svg {
    color: #0c3e72;
    font-size: 1.5rem;

}
/* --------------------------------------------------
    Mobile Menu
-------------------------------------------------- */
.mobile-nav-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
    background: rgba(7, 25, 33, 0.6);
    visibility: hidden;
}
.mobile-nav-overlay.active {
    visibility: visible;
}
.mobile-nav-wrap {
    position: fixed;
    display: block;
    height: 100%;
    width: 50%;
    left: -300px;
    top: 0px;
    padding: 10px 20px;
    overflow: auto;
    text-align: center;
    background: #fff;
    z-index: 9999;
    opacity: 0;
    transition: 0.5s;
}
.mobile-nav-wrap.active {
    opacity: 1;
    left: 0;
}
.mobile-nav-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}
.mobile-nav-menu ul li {
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
}
.mobile-nav-menu ul li a {
    display: block;
    margin: 0;
    padding: 10px 0 ;
    color: #0f394c;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
}
.mobile-nav-menu ul > li:hover .menu-link,
.mobile-nav-menu ul > li > .menu-link.menu-active {
	color: #fe5c24;
}
/* --------------------------------------------------
    Mobile Sub Menu
-------------------------------------------------- */
.mobile-nav-menu .nav-sub-menu li {
    border-top: 1px solid #e1e1e1;
    padding-left: 1.5rem;
    border-bottom: 0;
    width: 100%;
}
.mobile-nav-menu .nav-sub-menu li:hover .dropdown-link,
.mobile-nav-menu .nav-sub-menu li > ul > li:hover .sub-dropdown-link {
	color: #fe5c24;
}