.floating {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display:inline-flex !important;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border: none !important;
    outline: none !important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.floating svg {
    font-size: 1.25rem;
    font-weight: 700;
}
.scroll-none {
    display: none !important;
}