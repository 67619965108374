.question {
    margin-bottom: 60px;
}
/* --------------------------------------------------
    Title
-------------------------------------------------- */
.question .title {
    padding: 1rem 0;
    justify-content: center;
}
.question .title h1 {
    margin-bottom: 40px;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    color: #0c3e72;
}
/* --------------------------------------------------
    Accordion
-------------------------------------------------- */
.accordion {
    width: 100%;
    padding: 0 1.5rem;
}
.question .accordion-item {
    border-bottom: #0c3e72 1px solid;
}
.question .accordion-toggle {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.25rem 1rem 0; 
    border: 0;
    color: #0c3e72;
    font-size: 1.25rem;
    font-weight: 700;
    cursor: pointer;
}
.question .accordion-toggle .chevron {
    width: 10px;
    transition: transform 0.5s ease;
}
.question .accordion-collapse {
    height: 0;
    overflow: hidden;
    white-space: pre-line;
    color: #787878;
    transition: all 0.5s ease;
}
.question .accordion-toggle:hover,
.question .accordion-toggle.active {
    color: #fe5c24;
}
.question .accordion-toggle.active svg {
    transform: rotate(90deg);
}
.question .accordion-collapse.show {
    height: 90px;
}

.question .card-body {
    color: #787878;
    white-space: pre-line;
}
