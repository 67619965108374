/* --------------------------------------------------
    Banner
-------------------------------------------------- */
.banner .carousel .slide .carousel-text {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    margin-top: -133px;
    bottom: 40px;
    width: 100%;
    color: #fff;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out; 
}
.banner .carousel .slide .carousel-text .carousel-text-left,
.banner .carousel .slide .carousel-text .carousel-text-right{
    padding: 15px;
    opacity: 0.8;
    background-color: black;
}
.banner .carousel .slide .carousel-text .carousel-text-left h4,
.banner .carousel .slide .carousel-text .carousel-text-right div h3,
.banner .carousel .slide .carousel-text .carousel-text-right p{
    margin: 0;
}
.banner .carousel .slide .carousel-text .carousel-text-left {
    max-width: 150px;
}
.banner .carousel .slide .carousel-text .carousel-text-right {
    max-width: 350px;
}
.banner .carousel .slide .carousel-text .carousel-text-right div h3{
    padding-bottom: 10px;
}
.banner .carousel .slide .carousel-text .carousel-text-right p{
    font-size: 18px;
}
@media (max-width: 768px) {
    .banner .carousel .slide .carousel-text .carousel-text-left,
    .banner .carousel .slide .carousel-text .carousel-text-right {
        padding: 10px;
    }
    .banner .carousel .slide .carousel-text .carousel-text-left {
        max-width: 84px;
    }
    .banner .carousel .slide .carousel-text .carousel-text-left h4{
        font-size: 12px;
    }
    .banner .carousel .slide .carousel-text .carousel-text-right {
        max-width: 196px;
    }
    .banner .carousel .slide .carousel-text .carousel-text-right div h3{
        font-size: 14px;
    }
    .banner .carousel .slide .carousel-text .carousel-text-right div p{
        font-size: 9px;
    }
}
/* --------------------------------------------------
    About
-------------------------------------------------- */
.about {
    margin: 60px 0;
}
.about h1 {
    margin-bottom: 40px;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    color: #0c3e72;
}
.about p {
    font-size: 1.125rem;
    color: #787878;
}
.about span {
    margin: 0 0.375rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: #fe5c24;
}
/* --------------------------------------------------
    Service
-------------------------------------------------- */
.service {
    padding: 60px 0;
    text-align: center;
    color: white;
    background-color: #0c3e72;
}
.service .left {
    padding: 40px 0;
    border-right: 1px white solid;
}
.service .right {
    padding: 40px 0;
}
.service h1 {
    margin-bottom: 40px;
    font-size: 3rem;
    font-weight: 700;
}
.service hr {
    margin-bottom: 40px;
    width: 60px;
    border-top: 1px solid white;
}
/* --------------------------------------------------
    License
-------------------------------------------------- */
.license {
    padding: 60px 0;
}
.license h1 {
    margin-bottom: 40px;
    font-size: 3rem;
    font-weight: 700;
    color: #0c3e72;
}
.license .img-wrap {
    position: relative;
    height: 250px;
    width: 100%;
}
.license .img-wrap img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}