.footer {
    background-color: #0c3e72;
    color: white;
    text-align: center;
}
.footer hr {
    margin-bottom: 30px;
    width: 40px;
    border-top: 1px solid white;
}
.footer-contact p {
    margin: 0 0 5px 0;
}
.copyright {
    background-color: #08223a;
    padding: 25px 0;
    text-align: center;
    color: #777777;
    font-size: 2vmin;
}