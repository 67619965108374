* {
    font-family: "Dosis", sans-serif;
}
/* 預留空間讓Header可以常駐在底端 */
body { padding-top: 90px; }

/* Background */
.bg-orange{
	background-color: #fe5c24;
}
.bg-blue{
	background-color: #0c3e72;
}
  
/* Text */
.text-blue {
  color: #0c3e72;
}
.text-orange {
  color: #fe5c24;
}
.text-gray {
  color: #787878;
}

/* Button  */
.btn-lightblue {
	color: white !important;
	background-color: #0c3e72 !important;
}
.btn-lightblue:hover {
	color: white !important;
	background-color: #fe5c24 !important;
}
.btn-facebook {
	background-color: #3b5998 !important;
}
.btn-facebook:hover {
	background-color: #30497c !important;
}
.btn-line {
	background-color: #00c300 !important;
}
.btn-line:hover {
	background-color: #02a802 !important;
}
.btn-scroll-to-top {
    color: white !important;
	background-color:#5a5c69 !important;
}
.btn-scroll-to-top:hover {
	color: white !important;
    background-color:#fe5c24 !important;
}
.btn-blue,
.btn-facebook,
.btn-line {
	color: white !important;
	box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.btn-blue:hover,
.btn-facebook:hover,
.btn-line:hover {
	color: white !important;
	transform: translate(0,-2px);
}

/* Other */
.banner-height {
   height: 500px;
}
@media (max-width: 992px) {
    .banner-height {
        height: 450px;
    }
}
@media (max-width: 768px) {
    .banner-height {
        height: 400px;
    }
}
@media (max-width: 576px) {
    .banner-height {
        height: 350px;
    }
}